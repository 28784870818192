// material-ui
import { Box, FormControl, InputAdornment, OutlinedInput, InputLabel, MenuItem, ListItemText, Select, Checkbox } from '@mui/material';

// assets
import { SearchOutlined } from '@ant-design/icons';
import { useCommonHooks } from 'hooks/common';

// ==============================|| HEADER CONTENT - SEARCH ||============================== //

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    }
};

const Search = () => {
    const { shop, handleChangeSelectedShops } = useCommonHooks();

    const handleChange = (event) => {
        const {
            target: { value }
        } = event;
        handleChangeSelectedShops(value);
    };

    return (
        <Box sx={{ width: '100%', ml: { xs: 0, md: 1 } }}>
            <FormControl sx={{ m: 1, width: 300 }}>
                <InputLabel id="demo-multiple-checkbox-label">Outlet</InputLabel>
                <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={shop.selectedName}
                    onChange={handleChange}
                    input={<OutlinedInput label="Tag" />}
                    renderValue={(selected) => selected.join(', ')}
                    MenuProps={MenuProps}
                >
                    {shop.listName.map((name) => (
                        <MenuItem key={name} value={name}>
                            <Checkbox checked={shop.selectedName.indexOf(name) > -1} />
                            <ListItemText primary={name} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );
};

export default Search;
