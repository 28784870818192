import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';
import { element } from 'prop-types';

// render - dashboard
const DashboardDefault = Loadable(lazy(() => import('pages/dashboard')));
const ProductSales = Loadable(lazy(() => import('pages/product-sales')));
const CategorySales = Loadable(lazy(() => import('pages/category-sales')));
const DownloadReport = Loadable(lazy(() => import('pages/download-report/DownloadReport')));
const ProductList = Loadable(lazy(() => import('pages/product-list')));
const ProductDetail = Loadable(lazy(() => import('pages/product-list/ProductDetail')));
const IngredientList = Loadable(lazy(() => import('pages/ingredients')));
const IngredientRawNew = Loadable(lazy(() => import('pages/ingredients/IngredientRawNew')));
const IngredientRawDetail = Loadable(lazy(() => import('pages/ingredients/IngredientRawDetail')));
const IngredientRawBulk = Loadable(lazy(() => import('pages/ingredients/IngredientRawBulk')));
const IngredientSemiFinishNew = Loadable(lazy(() => import('pages/ingredients/IngredientSemiFinishNew')));
const IngredientSemiFinishDetail = Loadable(lazy(() => import('pages/ingredients/IngredientSemiFinishDetail')));
const IngredientSemiFinishEdit = Loadable(lazy(() => import('pages/ingredients/IngredientSemiFinishEdit')));
const VariantList = Loadable(lazy(() => import('pages/variants/VariantList')));
const VariantNew = Loadable(lazy(() => import('pages/variants/VariantNew')));
const VariantEdit = Loadable(lazy(() => import('pages/variants/VariantEdit')));
const RecipeList = Loadable(lazy(() => import('pages/recipes/RecipeList')));
const RecipeDetail = Loadable(lazy(() => import('pages/recipes/RecipeDetail')));
const Omset = Loadable(lazy(() => import('pages/omset')));
const BuyerList = Loadable(lazy(() => import('pages/buyers')));
const BuyerDetail = Loadable(lazy(() => import('pages/buyers/BuyerDetail')));
const BulkMessageListPage = Loadable(lazy(() => import('pages/bulk-message/BulkMessageList')));
const BulkMessageCreatePage = Loadable(lazy(() => import('pages/bulk-message/BulkMessageCreate')));
const BulkMessageDetailPage = Loadable(lazy(() => import('pages/bulk-message/BulkMessageDetail')));
const InventoryFlowPage = Loadable(lazy(() => import('pages/inventory-flow/InventoryFlow')));
const PurchasePage = Loadable(lazy(() => import('pages/inventory-flow/Purchase')));
const PurchaseDetailPage = Loadable(lazy(() => import('pages/inventory-flow/PurchaseDetail')));
const StockOpnamePage = Loadable(lazy(() => import('pages/inventory-flow/StockOpname')));
const StockOpnameDetailPage = Loadable(lazy(() => import('pages/inventory-flow/StockOpnameDetail')));
const TransferPage = Loadable(lazy(() => import('pages/inventory-flow/Transfer')));
const TransferDetailPage = Loadable(lazy(() => import('pages/inventory-flow/TransferDetail')));
const DeductionPage = Loadable(lazy(() => import('pages/inventory-flow/Deduction')));
const DeductionDetailPage = Loadable(lazy(() => import('pages/inventory-flow/DeductionDetail')));
const ShoppingPage = Loadable(lazy(() => import('pages/inventory-flow/Shopping')));
const ShoppingDetailPage = Loadable(lazy(() => import('pages/inventory-flow/ShoppingDetail')));
const ShoppingAdvancedPage = Loadable(lazy(() => import('pages/inventory-flow/ShoppingAdvanced')));
const Supplier = Loadable(lazy(() => import('pages/supplier')));
const SupplierDetail = Loadable(lazy(() => import('pages/supplier/SupplierDetail')));
const SupplierProducts = Loadable(lazy(() => import('pages/supplier/SupplierProducts')));
const CategoryInventory = Loadable(lazy(() => import('pages/category-inventory')));
// const Grabfood = Loadable(lazy(() => import('pages/grabfood')));
// const GrabfoodMenu = Loadable(lazy(() => import('pages/grabfood/GrabFoodProducts')));
const EmailReportListPage = Loadable(lazy(() => import('pages/email-report/EmailReportList')));
const EmailReportCreatePage = Loadable(lazy(() => import('pages/email-report/EmailReportCreate')));
const EmailReportEditPage = Loadable(lazy(() => import('pages/email-report/EmailReportEdit')));
const AttendanceHistoryListPage = Loadable(lazy(() => import('pages/attendance/AttendanceHistoryList')));
const AttendanceHistoryDetailPage = Loadable(lazy(() => import('pages/attendance/AttendanceHistoryDetail')));
const AttendanceApprovalListPage = Loadable(lazy(() => import('pages/attendance/AttendanceApprovalList')));
const AttendanceApprovalDetailPage = Loadable(lazy(() => import('pages/attendance/AttendanceApprovalDetail')));
const AreaListPage = Loadable(lazy(() => import('pages/table-management/AreaList')));
const TableListPage = Loadable(lazy(() => import('pages/table-management/TableList')));
const TableBulkPage = Loadable(lazy(() => import('pages/table-management/TableBulk')));
const OrderHistoryListPage = Loadable(lazy(() => import('pages/order-history/OrderHistoryList')));
const OrderHistoryDetailPage = Loadable(lazy(() => import('pages/order-history/OrderHistoryDetail')));
const ProductCategoryListPage = Loadable(lazy(() => import('pages/product-category/ProductCategoryList')));
const ProductCategoryNewPage = Loadable(lazy(() => import('pages/product-category/ProductCategoryNew')));
const ProductCategoryEditPage = Loadable(lazy(() => import('pages/product-category/ProductCategoryEdit')));
const VoucherListPage = Loadable(lazy(() => import('pages/vouchers/VoucherList')));
const VoucherNewPage = Loadable(lazy(() => import('pages/vouchers/VoucherNew')));
const InventoryFlowReport = Loadable(lazy(() => import('pages/inventory-flow-report')));
const MasterProductList = Loadable(lazy(() => import('pages/master-products')));
const MasterProductDetail = Loadable(lazy(() => import('pages/master-products/ProductDetail')));
const MasterVariantList = Loadable(lazy(() => import('pages/master-variants/MasterVariantList')));
const MasterVariantNew = Loadable(lazy(() => import('pages/master-variants/MasterVariantNew')));
const MasterVariantEdit = Loadable(lazy(() => import('pages/master-variants/MasterVariantEdit')));
const MasterProductCategoryList = Loadable(lazy(() => import('pages/master-product-category/MasterProductCategoryList')));
const MasterProductCategoryNew = Loadable(lazy(() => import('pages/master-product-category/MasterProductCategoryNew')));
const MasterProductCategoryEdit = Loadable(lazy(() => import('pages/master-product-category/MasterProductCategoryEdit')));
const AttendanceScheduleListPage = Loadable(lazy(() => import('pages/attendance/AttendanceScheduleList')));
const AttendanceScheduleNewPage = Loadable(lazy(() => import('pages/attendance/AttendanceScheduleNew')));
const AttendanceScheduleEditPage = Loadable(lazy(() => import('pages/attendance/AttendanceScheduleEdit')));

// render - utilities
const Typography = Loadable(lazy(() => import('pages/components-overview/Typography')));
const Color = Loadable(lazy(() => import('pages/components-overview/Color')));
const Shadow = Loadable(lazy(() => import('pages/components-overview/Shadow')));
const AntIcons = Loadable(lazy(() => import('pages/components-overview/AntIcons')));

// ==============================|| MAIN ROUTING ||============================== //

/** @type {import('react-router-dom').RouteObjects} */
const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <DashboardDefault />
        },
        {
            path: 'sales',
            element: <DashboardDefault />
        },
        {
            path: 'product-sales',
            element: <ProductSales />
        },
        {
            path: 'category-sales',
            element: <CategorySales />
        },
        {
            path: 'inventory-flow-report',
            element: <InventoryFlowReport />
        },
        {
            path: 'download-report',
            element: <DownloadReport />
        },
        {
            path: 'products',
            element: <ProductList />
        },
        {
            path: 'products/:id',
            element: <ProductDetail />
        },
        {
            path: 'products/new',
            element: <ProductDetail />
        },
        {
            path: 'master-products',
            element: <MasterProductList />
        },
        ,
        {
            path: 'master-products/:id',
            element: <MasterProductDetail />
        },
        {
            path: 'master-products/new',
            element: <MasterProductDetail />
        },
        {
            path: 'ingredients',
            children: [
                {
                    index: true,
                    element: <IngredientList />
                },
                {
                    path: 'raw',
                    children: [
                        {
                            path: 'new',
                            element: <IngredientRawNew />
                        },
                        {
                            path: 'bulk',
                            element: <IngredientRawBulk />
                        },
                        {
                            path: ':id',
                            element: <IngredientRawDetail />
                        }
                    ]
                },
                {
                    path: 'semi-finish',
                    children: [
                        {
                            path: 'new',
                            element: <IngredientSemiFinishNew />
                        },
                        {
                            path: ':id',
                            element: <IngredientSemiFinishDetail />
                        },
                        {
                            path: ':id/edit',
                            element: <IngredientSemiFinishEdit />
                        }
                    ]
                }
            ]
        },
        {
            path: 'inventory-flow',
            children: [
                {
                    index: true,
                    element: <InventoryFlowPage />
                },
                {
                    path: 'purchase',
                    children: [
                        {
                            index: true,
                            element: <PurchasePage />
                        },
                        {
                            path: ':id',
                            element: <PurchaseDetailPage />
                        }
                    ]
                },
                {
                    path: 'stock-opname',
                    children: [
                        {
                            index: true,
                            element: <StockOpnamePage />
                        },
                        {
                            path: ':id',
                            element: <StockOpnameDetailPage />
                        }
                    ]
                },
                {
                    path: 'deduction',
                    children: [
                        {
                            index: true,
                            element: <DeductionPage />
                        },
                        {
                            path: ':id',
                            element: <DeductionDetailPage />
                        }
                    ]
                },
                {
                    path: 'transfer',
                    children: [
                        {
                            index: true,
                            element: <TransferPage />
                        },
                        {
                            path: ':id',
                            element: <TransferDetailPage />
                        }
                    ]
                },
                {
                    path: 'shopping',
                    children: [
                        {
                            index: true,
                            element: <ShoppingPage />
                        },
                        {
                            path: ':id',
                            element: <ShoppingDetailPage />
                        }
                    ]
                },
                {
                    path: 'shopping-advanced',
                    children: [
                        {
                            index: true,
                            element: <ShoppingAdvancedPage />
                        }
                    ]
                }
            ]
        },
        {
            path: 'variants',
            children: [
                {
                    index: true,
                    element: <VariantList />
                },
                {
                    path: 'new',
                    element: <VariantNew />
                },
                {
                    path: 'edit/:id',
                    element: <VariantEdit />
                }
            ]
        },
        {
            path: 'product-category',
            children: [
                {
                    index: true,
                    element: <ProductCategoryListPage />
                },
                {
                    path: 'new',
                    element: <ProductCategoryNewPage />
                },
                {
                    path: ':id',
                    element: <ProductCategoryEditPage />
                }
            ]
        },
        {
            path: 'master-variants',
            children: [
                {
                    index: true,
                    element: <MasterVariantList />
                },
                {
                    path: 'new',
                    element: <MasterVariantNew />
                },
                {
                    path: 'edit/:id',
                    element: <MasterVariantEdit />
                }
            ]
        },
        {
            path: 'master-product-category',
            children: [
                {
                    index: true,
                    element: <MasterProductCategoryList />
                },
                {
                    path: 'new',
                    element: <MasterProductCategoryNew />
                },
                {
                    path: ':id',
                    element: <MasterProductCategoryEdit />
                }
            ]
        },
        {
            path: 'recipes',
            children: [
                {
                    index: true,
                    element: <RecipeList />
                },
                {
                    path: ':type/:id',
                    element: <RecipeDetail />
                }
            ]
        },
        {
            path: 'omset',
            element: <Omset />
        },
        {
            path: 'buyers',
            children: [
                {
                    index: true,
                    element: <BuyerList />
                },
                {
                    path: ':id',
                    element: <BuyerDetail />
                }
            ]
        },
        {
            path: 'bulk-messages',
            children: [
                {
                    index: true,
                    element: <BulkMessageListPage />
                },
                {
                    path: 'new',
                    element: <BulkMessageCreatePage />
                },
                {
                    path: ':id',
                    element: <BulkMessageDetailPage />
                }
            ]
        },
        {
            path: 'suppliers',
            children: [
                {
                    index: true,
                    element: <Supplier />
                },
                {
                    path: 'new',
                    element: <SupplierDetail />
                },
                {
                    path: ':id/products',
                    element: <SupplierProducts />
                },
                {
                    path: ':id',
                    element: <SupplierDetail />
                }
            ]
        },
        {
            path: 'categories-inventory',
            element: <CategoryInventory />
        },
        // {
        //     path: 'grabfood',
        //     children: [
        //         {
        //             index: true,
        //             element: <Grabfood />
        //         },
        //         {
        //             path: 'menu',
        //             element: <GrabfoodMenu />
        //         }
        //     ]
        // },
        {
            path: 'email-report',
            children: [
                {
                    index: true,
                    element: <EmailReportListPage />
                },
                {
                    path: 'new',
                    element: <EmailReportCreatePage />
                },
                {
                    path: ':id/edit',
                    element: <EmailReportEditPage />
                }
            ]
        },
        {
            path: 'attendance',
            children: [
                {
                    path: 'history',
                    children: [
                        {
                            index: true,
                            element: <AttendanceHistoryListPage />
                        },
                        {
                            path: ':id',
                            element: <AttendanceHistoryDetailPage />
                        }
                    ]
                },
                {
                    path: 'approval',
                    children: [
                        {
                            index: true,
                            element: <AttendanceApprovalListPage />
                        },
                        {
                            path: ':id',
                            element: <AttendanceApprovalDetailPage />
                        }
                    ]
                },
                {
                    path: 'schedule',
                    children: [
                        {
                            index: true,
                            element: <AttendanceScheduleListPage />
                        },
                        {
                            path: 'new',
                            element: <AttendanceScheduleNewPage />
                        },
                        {
                            path: ':id/:startTime',
                            element: <AttendanceScheduleEditPage />
                        }
                    ]
                }
            ]
        },
        {
            path: 'table-management',
            children: [
                {
                    path: 'area',
                    children: [
                        {
                            index: true,
                            element: <AreaListPage />
                        }
                    ]
                },
                {
                    path: 'table',
                    children: [
                        {
                            index: true,
                            element: <TableListPage />
                        },
                        {
                            path: 'create',
                            children: [
                                {
                                    path: 'bulk',
                                    element: <TableBulkPage />
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        {
            path: 'attendance',
            children: [
                {
                    path: 'history',
                    children: [
                        {
                            index: true,
                            element: <AttendanceHistoryListPage />
                        },
                        {
                            path: ':id',
                            element: <AttendanceHistoryDetailPage />
                        }
                    ]
                },
                {
                    path: 'approval',
                    children: [
                        {
                            index: true,
                            element: <AttendanceApprovalListPage />
                        },
                        {
                            path: ':id',
                            element: <AttendanceApprovalDetailPage />
                        }
                    ]
                }
            ]
        },
        {
            path: 'order-history',
            children: [
                {
                    index: true,
                    element: <OrderHistoryListPage />
                },
                {
                    path: ':id',
                    element: <OrderHistoryDetailPage />
                }
            ]
        },
        {
            path: 'vouchers',
            children: [
                {
                    index: true,
                    element: <VoucherListPage />
                },
                {
                    path: 'new',
                    element: <VoucherNewPage />
                },
                {
                    path: ':id',
                    element: null
                }
            ]
        }
    ]
};

export default MainRoutes;
