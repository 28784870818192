// URLS
const BASE_URL = import.meta.env.VITE_BASE_URL;
const API_CATEGORY_AUTH = '/auth';
const API_CATEGORY_CHART = '/charts';
const API_CATEGORY_SHOP = '/shop';
const API_CATEGORY_ORDER = '/order';
const API_CATEGORY_PRODUCT = '/product';
const API_CATEGORY_COMMON = '/common';
const API_VERSION_1 = '/v1';
const API_VERSION_2 = '/v2';

export const URL_GET_OTP_API = `${BASE_URL}${API_CATEGORY_AUTH}${API_VERSION_2}/login-otp-code`;
export const URL_VALIDATE_OTP_API = `${BASE_URL}${API_CATEGORY_AUTH}${API_VERSION_1}/dashboard/otp/validate`;
export const URL_SALES_SUMMARY_API = `${BASE_URL}${API_CATEGORY_ORDER}${API_VERSION_2}${API_CATEGORY_CHART}/sales-summary-v2?start={startDate}&end={endDate}&outlets={shopId}`;
export const URL_PRODUCT_SALES_API = `${BASE_URL}${API_CATEGORY_ORDER}${API_VERSION_2}${API_CATEGORY_CHART}/item-sales?start={startDate}&end={endDate}&type={type}&outlets={shopId}&category_ids={categoryIds}`;
export const URL_USER_SHOP_LIST_API = `${BASE_URL}${API_CATEGORY_SHOP}${API_VERSION_1}/shops-info`;
export const URL_USER_DATA_API = `${BASE_URL}${API_CATEGORY_AUTH}${API_VERSION_1}/user`;
export const URL_USER_INFO_API = `${BASE_URL}${API_CATEGORY_SHOP}${API_VERSION_1}/info/{shopId}`;
export const URL_USER_STAFF_LIST_API = `${BASE_URL}${API_CATEGORY_SHOP}${API_VERSION_1}/get-all-admin`;
export const URL_USER_STAFF_LIST_BY_SHOP_API = `${BASE_URL}${API_CATEGORY_SHOP}${API_VERSION_1}/get-all-user-shop/{shopId}`;
export const URL_CATEGORY_SALES_API = `${BASE_URL}${API_CATEGORY_ORDER}${API_VERSION_2}${API_CATEGORY_CHART}/category-sales?start={startDate}&end={endDate}&outlets={shopId}`;
export const URL_PRODUCT_LIST_API = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_1}${API_CATEGORY_SHOP}/{shopId}?image=true&variant=true`;
export const URL_PRODUCT_DETAIL_API = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_1}${API_CATEGORY_SHOP}/{shopId}/products/{productId}?image=true`;
export const URL_EDIT_PRODUCT_DETAIL_API = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_1}/{productId}`;
export const URL_ADD_PRODUCT_API = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_1}`;
export const URL_UPLOAD_IMAGE_API = `${BASE_URL}${API_CATEGORY_COMMON}${API_VERSION_1}/image`;
export const URL_CATEGORY_PRODUCT_API = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_1}${API_CATEGORY_SHOP}/{shopId}/categories`;
export const URL_CATEGORY_PRODUCT_ACTIVE_STATUS_API = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_2}${API_CATEGORY_SHOP}/{shopId}/categories/{categoryId}/active-status`;
export const URL_CATEGORY_PRODUCT_DETAIL_API = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_2}${API_CATEGORY_SHOP}/{shopId}/categories/{categoryId}`;
export const URL_VARIANT_PRODUCT_API = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_1}/get-new-variants${API_CATEGORY_SHOP}/{shopId}`;
export const URL_PRODUCT_STATUS = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_1}/status`;
export const URL_PRODUCT_STOCK_STATUS = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_1}/stock_status`;
export const URL_PRODUCT_BIND_VARIANT = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_1}/bind-product-to-variants${API_CATEGORY_SHOP}/{shopId}`;
export const URL_PRODUCT_DELETE_API = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_1}/delete-product`;
export const URL_INGREDIENT_LIST_API = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_1}/get-inventory-list${API_CATEGORY_SHOP}/{shopId}`;
export const URL_INGREDIENT_LIST_XLS_API = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_1}/download-inventories-file${API_CATEGORY_SHOP}/{shopId}`;
export const URL_ADD_INGREDIENT_API = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_1}/add-inventory-data${API_CATEGORY_SHOP}/{shopId}`;
export const URL_EDIT_INGREDIENT_API = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_1}/edit-inventory-data${API_CATEGORY_SHOP}/{shopId}/inventory/{ingredientId}`;
export const URL_INGREDIENT_DETAIL_API = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_1}/get-inventory-balance-list${API_CATEGORY_SHOP}/{shopId}/inventory/{ingredientId}`;
export const URL_CATEGORY_INGREDIENT_API = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_1}/get-category-inventory-list${API_CATEGORY_SHOP}/{shopId}`;
export const URL_SUPPLIER_INGREDIENT_API = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_1}/get-supplier-inventory-list${API_CATEGORY_SHOP}/{shopId}`;
export const URL_SUPPLIER_INGREDIENT_DETAIL_API = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_1}/get-inventory-supplier-details`;
export const URL_ADD_SUPPLIER_INGREDIENT_DETAIL_PRODUCT_API = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_1}/add-inventory-supplier-products`;
export const URL_EDIT_SUPPLIER_INGREDIENT_DETAIL_PRODUCT_API = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_1}/edit-delete-inventory-supplier-products`;
export const URL_MODIFY_INGREDIENT_STOCK_API = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_1}/modify-inventory${API_CATEGORY_SHOP}/{shopId}`;
export const URL_INGREDIENT_SEMI_FINISH_CREATE_NEW_API = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_1}/add-semi-finish`;
export const URL_INGREDIENT_SEMI_FINISH_EDIT_API = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_1}/edit-semi-finish`;
export const URL_INGREDIENT_SEMI_FINISH_LIST_RAW_INGREDIENTS_API = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_1}/get-raw-data-list-from-semi-finish`;
export const URL_INGREDIENT_SEMI_FINISH_EDIT_RECIPE_API = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_1}/add-raw-data-to-semi-finish`;
export const URL_INGREDIENT_SEMI_FINISH_DELETE_RAW_INGREDIENT_API = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_1}/delete-raw-data-to-semi-finish`;
export const URL_INGREDIENT_SEMI_FINISH_ADD_RAW_INGREDIENTS_API = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_1}/bind-raw-to-semi-finish`;
export const URL_INGREDIENT_RAW_UPLOAD_BULK_API = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_1}/validate-add-raw-bulk${API_CATEGORY_SHOP}/{shopId}`;
export const URL_INGREDIENT_RAW_ADD_BULK_API = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_1}/add-raw-bulk${API_CATEGORY_SHOP}/{shopId}`;
export const URL_INGREDIENT_DELETE_API = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_1}/delete-inventory`;
export const URL_VARIANT_LIST_API = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_1}/get-new-variants${API_CATEGORY_SHOP}/{shopId}`;
export const URL_VARIANT_CREATE_NEW_API = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_1}/create-new-variant${API_CATEGORY_SHOP}/{shopId}`;
export const URL_VARIANT_UPDATE_API = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_2}/edit-new-variant${API_CATEGORY_SHOP}/{shopId}`;
export const URL_VARIANT_CATEGORY_LIST_API = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_1}${API_CATEGORY_SHOP}/{shopId}/categories`;
export const URL_VARIANT_PRODUCT_LIST_API = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_1}${API_CATEGORY_SHOP}/{shopId}?image=true&variant=true`;
export const URL_VARIANT_SET_PRODUCT_AS_VARIANT_API = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_1}/set-product-as-variant/{shopId}`;
export const URL_VARIANT_BIND_VARIANT_API = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_1}/bind-variants${API_CATEGORY_SHOP}/{shopId}`;
export const URL_VARIANT_DELETE_API = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_2}${API_CATEGORY_SHOP}/{shopId}/variant/{variantId}`;
export const URL_VARIANT_UPDATE_OUT_OF_STOCK_API = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_1}/set-variant-oos-flag${API_CATEGORY_SHOP}/{shopId}`;
export const URL_MASTER_VARIANT_LIST_API = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_1}/get-master-variants`;
export const URL_MASTER_VARIANT_CREATE_API = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_1}/create-master-variant`;
export const URL_MASTER_VARIANT_EDIT_API = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_1}/edit-master-variant`;
export const URL_MASTER_VARIANT_BIND_TO_PRODUCTS_API = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_1}/bind-master-variant`;
export const URL_MASTER_VARIANT_UPDATE_OUT_OF_STOCK_API = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_1}/set-master-variant-oos`;
export const URL_MASTER_VARIANT_IMPORT_API = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_1}/create-master-variant-from-variant`;
export const URL_MASTER_VARIANT_DELETE_API = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_1}/delete-master-variant`;
export const URL_MASTER_VARIANT_SYNC_TO_VARIANT_API = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_1}/sync-master-variant-to-variant`;
// TODO: CHANGE THIS TO MASTER PRODUCT
export const URL_MASTER_VARIANT_PRODUCT_LIST_API = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_1}/get-master-products`;
export const URL_MASTER_CATEGORY_PRODUCT_API = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_1}/get-master-categories`;
export const URL_MASTER_CATEGORY_PRODUCT_BY_ID_API = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_1}/get-master-category-by-id`;
export const URL_MASTER_CATEGORY_PRODUCT_CREATE_API = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_1}/create-master-categories`;
export const URL_MASTER_CATEGORY_PRODUCT_EDIT_API = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_1}/edit-master-category`;
export const URL_MASTER_CATEGORY_PRODUCT_UPDATE_STATUS_API = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_2}/active-master-categories/{categoryId}/active-status`;
export const URL_MASTER_CATEGORY_PRODUCT_IMPORT_API = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_1}/create-master-category-from-category`;
export const URL_RECIPE_FROM_PRODUCT_LIST_API = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_1}/get-recipe-list${API_CATEGORY_SHOP}/{shopId}`;
export const URL_RECIPE_FROM_VARIANT_LIST_API = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_1}/get-recipe-variant-list${API_CATEGORY_SHOP}/{shopId}`;
export const URL_RECIPE_FROM_PRODUCT_DETAIL_API = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_1}/get-recipe${API_CATEGORY_SHOP}/{shopId}${API_CATEGORY_PRODUCT}/{productId}`;
export const URL_RECIPE_FROM_VARIANT_DETAIL_API = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_1}/get-recipe-variant${API_CATEGORY_SHOP}/{shopId}/variant-detail/{variantId}`;
export const URL_RECIPE_UPDATE_INGREDIENT_FROM_PRODUCT_API = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_1}/modify-recipe${API_CATEGORY_SHOP}/{shopId}`;
export const URL_RECIPE_UPDATE_INGREDIENT_FROM_VARIANT_API = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_1}/modify-recipe-variant${API_CATEGORY_SHOP}/{shopId}`;
export const URL_RECIPE_INGREDIENT_LIST_API = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_1}/get-inventory-list${API_CATEGORY_SHOP}/{shopId}`;
export const URL_RECIPE_INGREDIENT_CATEGORY_LIST_API = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_1}/get-category-inventory-list${API_CATEGORY_SHOP}/{shopId}`;
export const URL_RECIPE_ADD_INGREDIENT_FROM_PRODUCT_API = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_1}/add-recipe${API_CATEGORY_SHOP}/{shopId}`;
export const URL_RECIPE_ADD_INGREDIENT_FROM_VARIANT_API = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_1}/add-recipe-variant${API_CATEGORY_SHOP}/{shopId}`;
export const URL_RECIPE_DELETE_INGREDIENT_FROM_PRODUCT_API = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_1}/delete-from-recipe${API_CATEGORY_SHOP}/{shopId}`;
export const URL_RECIPE_DELETE_INGREDIENT_FROM_VARIANT_API = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_1}/delete-from-variant-recipe${API_CATEGORY_SHOP}/{shopId}`;
export const URL_OMSET_PRODUCT_API = `${BASE_URL}${API_CATEGORY_ORDER}${API_VERSION_1}/get-order-recipe-summary`;
export const URL_OMSET_VARIANT_API = `${BASE_URL}${API_CATEGORY_ORDER}${API_VERSION_1}/get-order-recipe-variant-summary`;
export const URL_BUYER_LIST = `${BASE_URL}${API_CATEGORY_ORDER}${API_VERSION_1}/get-buyer${API_CATEGORY_SHOP}/{shopId}`;
export const URL_BUYER_RESERVATIONS = `${BASE_URL}${API_CATEGORY_ORDER}${API_VERSION_2}${API_CATEGORY_SHOP}/{shopId}/reservations?page=1&perPage=9999999&phoneNumber={phone}`;
export const URL_BUYER_DETAIL_ORDERS = `${BASE_URL}${API_CATEGORY_ORDER}${API_VERSION_2}/get-orders-paginated${API_CATEGORY_SHOP}/{shopId}`;
export const URL_BUYER_DETAIL = `${BASE_URL}${API_CATEGORY_ORDER}${API_VERSION_1}/get-buyer/summary`;
export const URL_BUYER_EDIT_BIRTHDAY = `${BASE_URL}${API_CATEGORY_ORDER}${API_VERSION_2}/update-shop-customer-birthdate${API_CATEGORY_SHOP}/{shopId}`;
export const URL_PURCHASE_BULK = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_1}/modify-inventory-bulk-add${API_CATEGORY_SHOP}/{shopId}`;
export const URL_STOCK_OPNAME_BULK = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_1}/modify-inventory-adjustment${API_CATEGORY_SHOP}/{shopId}`;
export const URL_DEDUCTION_BULK = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_1}/modify-inventory-bulk-deduct${API_CATEGORY_SHOP}/{shopId}`;
export const URL_INVENTORY_ADD_SUPPLIER = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_1}/add-inventory-supplier${API_CATEGORY_SHOP}/{shopId}`;
export const URL_INVENTORY_ADD_CATEGORY = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_1}/add-inventory-category${API_CATEGORY_SHOP}/{shopId}`;
export const URL_INVENTORY_EDIT_SUPPLIER = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_1}/edit-inventory-supplier${API_CATEGORY_SHOP}/{shopId}/supplier/{supplierId}`;
export const URL_INVENTORY_EDIT_CATEGORY = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_1}/edit-inventory-category${API_CATEGORY_SHOP}/{shopId}/category/{categoryId}`;
export const URL_GRABFOOD_REQUEST = `${BASE_URL}${API_CATEGORY_SHOP}${API_VERSION_2}/shops/{shopId}/request-auto-grabfood`;
export const URL_SHOP_INFO = `${BASE_URL}${API_CATEGORY_SHOP}${API_VERSION_1}/info/{shopId}`;
export const URL_GRAB_BULK_EXPORT = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_1}/bulk-update-grabfood`;
export const URL_INVENTORY_FLOW_LIST = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_1}/get-inventory-modification-list${API_CATEGORY_SHOP}/{shopId}`;
export const URL_INVENTORY_FLOW_DETAIL = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_1}/get-inventory-operation-detail${API_CATEGORY_SHOP}/{shopId}`;
export const URL_INVENTORY_FLOW_INIT_TRANSFER = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_1}/init-inventory-transfer${API_CATEGORY_SHOP}/{shopId}`;
export const URL_INVENTORY_FLOW_DETAIL_TRANSFER = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_1}/get-inventory-transfer-detail${API_CATEGORY_SHOP}/{shopId}`;
export const URL_INVENTORY_FLOW_MATCH_INGREDIENT_TRANSFER = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_1}/match-inventory-transfer${API_CATEGORY_SHOP}/{shopId}`;
export const URL_INVENTORY_FLOW_REJECT_TRANSFER = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_1}/reject-inventory-transfer${API_CATEGORY_SHOP}/{shopId}`;
export const URL_INVENTORY_FLOW_LATEST_INGREDIENT = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_1}/get-last-shopping-list`;
export const URL_INVENTORY_FLOW_CREATE_SHOPPING_LIST = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_1}/modify-inventory-shopping-list${API_CATEGORY_SHOP}/{shopId}`;
export const URL_INVENTORY_FLOW_CREATE_SHOPPING_LIST_ADVANCED = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_1}/insert-shopping-list-advanced-view${API_CATEGORY_SHOP}/{shopId}`;
export const URL_INVENTORY_FLOW_REJECT_SHOPPING_LIST = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_1}/reject-shopping-list${API_CATEGORY_SHOP}/{shopId}`;
export const URL_INVENTORY_FLOW_MATCH_SHOPPING_LIST = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_1}/match-shopping-list${API_CATEGORY_SHOP}/{shopId}`;
export const URL_DOWNLOAD_REPORT = `${BASE_URL}${API_CATEGORY_ORDER}${API_VERSION_2}/generate-report-async`;
export const URL_SHIFT_BY_DATE = `${BASE_URL}${API_CATEGORY_ORDER}${API_VERSION_1}/get-shifts-by-date${API_CATEGORY_SHOP}/{shopId}`;
export const URL_BULK_MESSAGES_LIST = `${BASE_URL}${API_CATEGORY_ORDER}${API_VERSION_2}/get-all-custom-wa-template${API_CATEGORY_SHOP}/{shopId}`;
export const URL_BULK_MESSAGES_CREATE_NEW = `${BASE_URL}${API_CATEGORY_ORDER}${API_VERSION_1}/create-custom-wa`;
export const URL_GET_SELLER_COINS = `${BASE_URL}${API_CATEGORY_SHOP}${API_VERSION_1}/payment/{shopId}`;
export const URL_EMAIL_REPORT_LIST = `${BASE_URL}${API_CATEGORY_ORDER}${API_VERSION_1}/get-email-schedule-list`;
export const URL_EMAIL_REPORT_SET_STATUS = `${BASE_URL}${API_CATEGORY_ORDER}${API_VERSION_1}/set-email-schedule-active`;
export const URL_EMAIL_REPORT_CREATE_NEW = `${BASE_URL}${API_CATEGORY_ORDER}${API_VERSION_1}/create-email-schedule`;
export const URL_EMAIL_REPORT_EDIT = `${BASE_URL}${API_CATEGORY_ORDER}${API_VERSION_1}/edit-email-schedule`;
export const URL_ATTENDANCE_HISTORY_LIST = `${BASE_URL}${API_CATEGORY_SHOP}${API_VERSION_1}/get-attendance-list`;
export const URL_ATTENDANCE_HISTORY_DETAIL = `${BASE_URL}${API_CATEGORY_SHOP}${API_VERSION_1}/get-attendance-by-id`;
export const URL_ATTENDANCE_APPROVAL_ACTION = `${BASE_URL}${API_CATEGORY_SHOP}${API_VERSION_1}/attendance-approval-action`;
export const URL_ATTENDANCE_APPROVAL_LIST = `${BASE_URL}${API_CATEGORY_SHOP}${API_VERSION_1}/get-attendance-approval-list`;
export const URL_ATTENDANCE_SCHEDULE_LIST = `${BASE_URL}${API_CATEGORY_SHOP}${API_VERSION_1}/get-schedule-attendance-list`;
export const URL_ATTENDANCE_SCHEDULE_CREATE_NEW = `${BASE_URL}${API_CATEGORY_SHOP}${API_VERSION_1}/schedule-attendance-create`;
export const URL_ATTENDANCE_SCHEDULE_BY_ID = `${BASE_URL}${API_CATEGORY_SHOP}${API_VERSION_1}/get-schedule-attendance-single`;
export const URL_ATTENDANCE_SCHEDULE_EDIT = `${BASE_URL}${API_CATEGORY_SHOP}${API_VERSION_1}/schedule-attendance-edit`;
export const URL_ATTENDANCE_SCHEDULE_DELETE = `${BASE_URL}${API_CATEGORY_SHOP}${API_VERSION_1}/schedule-attendance-delete`;
export const URL_TABLE_MANAGEMENT_AREA_TABLE_LIST = `${BASE_URL}${API_CATEGORY_ORDER}${API_VERSION_2}/get-bench-area-list`;
export const URL_TABLE_MANAGEMENT_AREA_CREATE_NEW = `${BASE_URL}${API_CATEGORY_ORDER}${API_VERSION_2}/create-bench-area`;
export const URL_TABLE_MANAGEMENT_AREA_EDIT = `${BASE_URL}${API_CATEGORY_ORDER}${API_VERSION_2}/edit-bench-area`;
export const URL_TABLE_MANAGEMENT_AREA_DELETE = `${BASE_URL}${API_CATEGORY_ORDER}${API_VERSION_2}/delete-bench-area`;
export const URL_TABLE_MANAGEMENT_TABLE_CREATE_NEW = `${BASE_URL}${API_CATEGORY_ORDER}${API_VERSION_2}/create-bench`;
export const URL_TABLE_MANAGEMENT_TABLE_EDIT = `${BASE_URL}${API_CATEGORY_ORDER}${API_VERSION_2}/edit-bench`;
export const URL_TABLE_MANAGEMENT_TABLE_DELETE = `${BASE_URL}${API_CATEGORY_ORDER}${API_VERSION_2}/delete-bench`;
export const URL_TABLE_MANAGEMENT_TABLE_BULK_UPLOAD_EXCEL = `${BASE_URL}${API_CATEGORY_ORDER}${API_VERSION_2}/validate-bench-bulk${API_CATEGORY_SHOP}/{shopId}`;
export const URL_TABLE_MANAGEMENT_TABLE_ADD_BULK = `${BASE_URL}${API_CATEGORY_ORDER}${API_VERSION_2}/upload-bench-bulk${API_CATEGORY_SHOP}/{shopId}`;
export const URL_ORDER_HISTORY_LIST = `${BASE_URL}${API_CATEGORY_ORDER}${API_VERSION_2}/get-orders-paginated${API_CATEGORY_SHOP}/{shopId}`;
export const URL_ORDER_HISTORY_DELETE = `${BASE_URL}${API_CATEGORY_ORDER}${API_VERSION_2}${API_CATEGORY_SHOP}/{shopId}/archive-order/{orderSN}`;
export const URL_ORDER_HISTORY_DELETE_BULK = `${BASE_URL}${API_CATEGORY_ORDER}${API_VERSION_2}${API_CATEGORY_SHOP}/{shopId}/archive-orders`;
export const URL_ORDER_HISTORY_UPDATE_DATE = `${BASE_URL}${API_CATEGORY_ORDER}${API_VERSION_2}/shops/{shopId}/update-orders-date`;
export const URL_ORDER_DETAIL = `${BASE_URL}${API_CATEGORY_ORDER}${API_VERSION_1}/{orderSN}`;
export const URL_VOUCHERS_LIST = `${BASE_URL}${API_CATEGORY_ORDER}${API_VERSION_2}/promotion-shop${API_CATEGORY_SHOP}/{shopId}/type/{type}`;
export const URL_CREATE_VOUCHER = `${BASE_URL}${API_CATEGORY_ORDER}${API_VERSION_1}/promotion`;
export const URL_INVENTORY_FLOW_REPORT_LIST = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_1}/get-inventory-flow`;
export const URL_REGISTER_USER = `${BASE_URL}${API_CATEGORY_AUTH}${API_VERSION_1}/register`;
export const URL_SHOP_BY_USER = `${BASE_URL}${API_CATEGORY_SHOP}${API_VERSION_1}`;
export const URL_SHOP = `${BASE_URL}${API_CATEGORY_SHOP}${API_VERSION_2}`;
export const URL_MASTER_PRODUCT_LIST = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_1}/get-master-products`;
export const URL_MASTER_PRODUCT_DETAIL = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_1}/get-master-product-by-id`;
export const URL_MASTER_PRODUCT_CREATE = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_2}/create-master-product`;
export const URL_MASTER_PRODUCT_BIND_VARIANTS = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_1}/bind-master-product-to-variants`;
export const URL_MASTER_PRODUCT_STATUS_OOS = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_1}/set-master-product-oos`;
export const URL_MASTER_PRODUCT_DETAIL_EDIT = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_1}/edit-master-product`;
export const URL_MASTER_PRODUCT_LINK_TO_SHOP = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_1}/link-product-master`;
export const URL_MASTER_PRODUCT_DELETE_LINKED_TO_SHOP = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_1}/delete-linked-product-master`;
export const URL_MASTER_PRODUCT_CREATE_FROM_SHOP_PRODUCTS = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_1}/create-master-product-from-product`;
export const URL_MASTER_PRODUCT_REVERT_VERSION_BACK = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_1}/sync-master-product-to-product`;
export const URL_SUMMARY_ORDER_MATRIC = `${BASE_URL}${API_CATEGORY_ORDER}${API_VERSION_2}/get-order-report-metrics?start={startDate}&end={endDate}&outlets={shopId}`;

// UNIT OF MEASUREMENTS
export const UOM_LIST = {
    kg: 'general.OpUnitKg',
    g: 'general.OpUnitG',
    liter: 'general.OpUnitLiter',
    milliliter: 'general.OpUnitMilliliter',
    piece: 'general.OpUnitPiece',
    cup: 'general.OpUnitCup',
    tablespoon: 'general.OpUnitTablespoon',
    teaspoon: 'general.OpUnitTeaspoon'
};

export const UOM_CONVERTABLE_LIST = {
    kg: ['kg', 'g'],
    g: ['g', 'kg'],
    liter: ['liter', 'milliliter'],
    milliliter: ['milliliter', 'liter'],
    piece: [],
    cup: [],
    tablespoon: [],
    teaspoon: []
};

// LOCAL STORAGE KEYS
export const LS_AUTH = 'op_auth';
export const LS_USER = 'op_user';
export const LS_SHOP = 'op_shop';
export const LS_USERNAME = 'op_username';
export const LS_PHONE_NUMBER = 'op_phone_number';
export const LS_SELECTED_SHOP_NAME = 'op_selected_shop_name';
export const LS_LANG = 'op_lang';
export const MAX_BIG_INT = 2147483647;
export const GOOGLE_API_KEY = import.meta.env.REACT_APP_API_KEY;
export const MONAS_LAT = -6.175392;
export const MONAS_LNG = 106.827153;

// VARIANT CONSTANT
export const VARIANT_FORM_KEY = {
    NAME: 'name',
    IS_UNLIMITED_OPTION: 'isUnlimitedOption',
    NUM_OPTION: 'numOption',
    IS_MANDATORY: 'isMandatory',
    IS_MASTER: 'isMaster',
    OPTION_ID: 'id',
    OPTION_NAME: 'optionName',
    OPTION_PRICE: 'price',
    OPTION_GOFOOD: 'gofood',
    OPTION_GRABFOOD: 'grabfood',
    OPTION_SHOPEEFOOD: 'shopeefood',
    OPTION_REMAINING_STOCK: 'remainingStock',
    OPTION_IS_LIMITED_STOCK: 'isLimitedStock',
    OPTION_STOCK_TYPE: 'stockType',
    OPTION_STOCK_NUM: 'stockNum',
    OPTION_IS_ACTIVE: 'isActive',
    OPTION_PRODUCT_ID: 'productId'
};

export const VARIANT_TYPE = {
    OWN_VARIANT: 'OWN_VARIANT',
    MENU_VARIANT: 'MENU_VARIANT'
};

export const VARIANT_APPLY_PRODUCT_TYPE = {
    OPTIONS_MENU: 'optionsMenu',
    APPLY_MENU: 'applyMenu'
};

export const VARIANT_PRODUCT_CATEGORIES_KEY = {
    ID: 'id',
    RANK: 'rank',
    NAME: 'name'
};

export const VARIANT_PRODUCTS_KEY = {
    ID: 'id',
    IMAGE_URL: 'imageUrl',
    NAME: 'name',
    PRICE: 'price',
    PRICE_GOFOOD: 'gofood',
    PRICE_GRABFOOD: 'grabfood',
    PRICE_SHOPEEFOOD: 'shopeefood',
    IS_LIMITED_STOCK: 'isLimitedStock',
    STOCK_TYPE: 'stockType',
    STOCK_NUM: 'stockNum',
    REMAINING_STOCK: 'remainingStock',
    IS_ACTIVE: 'isActive',
    IS_VARIANT: 'isVariant',
    HAS_VARIANTS: 'hasVariants',
    CATEGORIES: 'categories'
};

// INGREDIENTS CONSTANT
export const INGREDIENTS_MODE = {
    RAW: 'raw',
    SEMI_FINISH: 'semi-finish',
    ADD_BULK: 'addBulk'
};

export const INGREDIENT_TYPE_LANG = {
    raw: 'general.OpIngredientRaw',
    'semi-finish': 'general.OpIngredientSemiFinish'
};

// RECIPE CONSTANT
export const RECIPE_TAB_MODE_KEY = {
    PRODUCT: 'product',
    VARIANT: 'variant'
};

export const RECIPE_TAB_MODES = [RECIPE_TAB_MODE_KEY.PRODUCT, RECIPE_TAB_MODE_KEY.VARIANT];

export const RECIPE_DETAIL_KEY = {
    NAME: 'name',
    DESCRIPTION: 'description',
    TOTAL_PRICE: 'totalPrice',
    TOTAL_INGREDIENTS: 'totalIngredients',
    VARIANT_ID: 'variantId'
};

export const RECIPE_INGREDIENTS_KEY = {
    ID: 'id',
    CATEGORY: 'category',
    NAME: 'name',
    TYPE: 'type'
};

export const RECIPE_INGREDIENT_CATEGORIES_KEY = {
    ID: 'id',
    NAME: 'name'
};

// INVENTORY FLOW CONSTANTS
export const INVENTORY_FLOW_TYPE = {
    PURCHASE: 'PURCHASE',
    DEDUCTION: 'DEDUCTION',
    STOCK_OPNAME: 'STOCK_OPNAME',
    TRANSFER: 'TRANSFER',
    SHOPPING: 'SHOPPING',
    SHOPPING_ADVANCED: 'SHOPPING_ADVANCED'
};

export const INVENTORY_FLOW_TRANSFER_TYPE = {
    REQUEST: 'request',
    SEND: 'send'
};

export const INVENTORY_FLOW_TRANSFER_TYPE_LANG = {
    request: 'inventory_flow.OpRequestTransferStock',
    send: 'inventory_flow.OpSendTransferStock'
};

export const INVENTORY_FLOW_TRANSFER_STATUS_MAP = {
    init: 'inventory_flow.OpStatusInit',
    completed: 'inventory_flow.OpStatusCompleted',
    shipped: 'inventory_flow.OpStatusShipped',
    rejected: 'inventory_flow.OpStatusRejected'
};

export const INVENTORY_FLOW_SHOPPING_LIST_REJECT_MAP = {
    missing: 'inventory_flow.OpRejectReasonMissing',
    broken: 'inventory_flow.OpRejectReasonBroken',
    not_ordered: 'inventory_flow.OpRejectReasonNotOrdered'
};

export const INVENTORY_FLOW_DEDUCTION_REJECT_MAP = {
    missing: 'inventory_flow.OpRejectReasonMissing',
    broken: 'inventory_flow.OpRejectReasonBroken'
};

export const BULK_MESSAGES_STATUS_TEXT = {
    NEW: 'bulk_message.OpStatusPending',
    APPROVED: 'bulk_message.OpStatusApproved',
    PENDING: 'bulk_message.OpStatusPending',
    REJECTED: 'bulk_message.OpStatusRejected',
    PENDING_DELETION: 'bulk_message.OpStatusRejected',
    DELETED: 'bulk_message.OpStatusRejected'
};

export const BULK_MESSAGES_STATUS_BG_COLOR = {
    NEW: '#FFF8E6',
    APPROVED: '#E6F7ED',
    PENDING: '#FFF8E6',
    REJECTED: '#FCEDEA',
    PENDING_DELETION: '#FCEDEA',
    DELETED: '#FCEDEA'
};

export const BULK_MESSAGES_STATUS_TEXT_COLOR = {
    NEW: '#E6A603',
    APPROVED: '#00AB4F',
    PENDING: '#E6A603',
    REJECTED: '#DE4E29',
    PENDING_DELETION: '#DE4E29',
    DELETED: '#DE4E29'
};

// Email Reports Constants
export const EMAIL_REPORT_REPORT_TYPE = {
    order: 'general.OpTypeOrder',
    balance: 'general.OpTypeBalance',
    item: 'general.OpTypeItem',
    item_sold: 'general.OpTypeItemSold'
};

export const EMAIL_REPORT_REPEAT_TYPE = {
    // hourly: 'Per Jam',
    daily: 'general.OpDaily',
    weekly: 'general.OpWeekly',
    monthly: 'general.OpMonthly'
};

export const EMAIL_REPORT_DAY_TYPE = {
    mon: 'general.OpMonday',
    tue: 'general.OpTuesday',
    wed: 'general.OpWednesday',
    thu: 'general.OpThursday',
    fri: 'general.OpFriday',
    sat: 'general.OpSaturday',
    sun: 'general.OpSunday'
};

export const EMAIL_REPORT_DATE_TYPE = {
    [1]: 'general.OpDateFirstOfMonth',
    [15]: 'general.OpDateMidOfMonth',
    end_month: 'general.OpDateEndOfMonth'
};

// Attendance History
export const ATTENDANCE_HISTORY_STATUS_TEXT = {
    requested: 'attendance_history.OpStatusRequested',
    rejected: 'attendance_history.OpStatusRejected',
    approved: 'attendance_history.OpStatusApproved',
    deleted: 'attendance_history.OpStatusDeleted',
    submitted: 'attendance_history.OpStatusSubmitted'
};

export const ATTENDANCE_HISTORY_STATUS_BG_COLOR = {
    requested: '#E9F6FF',
    rejected: '#FCEDEA',
    approved: '#E6F7ED',
    deleted: '#FCEDEA',
    submitted: '#FFF8E6'
};

export const ATTENDANCE_HISTORY_STATUS_TEXT_COLOR = {
    requested: '#1EA5FC',
    rejected: '#DE4E29',
    approved: '#00AB4F',
    deleted: '#DE4E29',
    submitted: '#E6A603'
};

export const ATTENDANCE_APPROVAL_TAB_LIST = ['requested', 'approved', 'rejected'];

export const ATTENDANCE_APPROVAL_TAB_LIST_LANG = {
    requested: 'attendance_approval.OpStatusPending',
    approved: 'attendance_history.OpStatusApproved',
    rejected: 'attendance_history.OpStatusRejected'
};

// Order History
export const ORDER_HISTORY_STATUS_TEXT = {
    created: 'general.OpOrderStatusCreated',
    ready_to_ship: 'general.OpOrderStatusReadyToShip',
    shipped: 'general.OpOrderStatusShipped',
    completed: 'general.OpOrderStatusCompleted',
    cancelled: 'general.OpOrderStatusCancelled'
};

export const ORDER_HISTORY_STATUS_BG_COLOR = {
    created: '#DE4E291F',
    ready_to_ship: '#FFF8E6',
    shipped: '#E6F7ED',
    completed: '#E9F6FF',
    cancelled: '#DCE1E7'
};

export const ORDER_HISTORY_STATUS_TEXT_COLOR = {
    created: '#DE4E29',
    ready_to_ship: '#FFB803',
    shipped: '#00AB4F',
    completed: '#1EA5FC',
    cancelled: '#596363'
};

export const ORDER_HISTORY_STATUS_FILTER_MAP = {
    all: 'general.OpOrderStatusAll',
    created: 'general.OpOrderStatusCreated',
    ready_to_ship: 'general.OpOrderStatusReadyToShip',
    shipped: 'general.OpOrderStatusShipped',
    completed: 'general.OpOrderStatusCompleted',
    cancelled: 'general.OpOrderStatusCancelled'
};

export const ORDER_HISTORY_PAYMENT_TYPE_FILTER_MAP = {
    all: 'general.OpPaymentAll',
    cash: 'general.OpPaymentCash',
    'manual-edc': 'general.OpPaymentManualEdc',
    BANK_TRANSFER: 'general.OpPaymentTransfer',
    EWALLET: 'general.OpPaymentEwallet',
    QRIS: 'general.OpPaymentQris'
};

export const ORDER_HISTORY_SHIPPING_TYPE_FILTER_MAP = {
    all: 'general.OpShippingAll',
    dine_in: 'general.OpShippingDineIn',
    pickup: 'general.OpShippingPickup',
    grabfood: 'general.OpShippingGrabFood',
    gofood: 'general.OpShippingGoFood',
    shopeefood: 'general.OpShippingShopeeFood',
    'GO-SEND': 'GO-SEND',
    'Grab Express': 'Grab Express',
    Paxel: 'Paxel',
    JNE: 'JNE',
    Tiki: 'Tiki',
    'J&T': 'J&T',
    Wahana: 'Wahana',
    SiCepat: 'SiCepat',
    Anteraja: 'Anteraja',
    'Ninja Xpress': 'Ninja Xpress',
    'Lion Parcel': 'Lion Parcel',
    'Indah Cargo': 'Indah Cargo',
    RPX: 'RPX',
    Borzo: 'Borzo',
    Lalamove: 'Lalamove'
};

export const ORDER_HISTORY_SHIPPING_TYPE_BACKEND_VALUE_MAP = {
    dine_in: ['dine_in', 'open_bill'],
    pickup: ['pickup'],
    self_shipping: ['self_shipping'],
    grabfood: ['grabfood'],
    gofood: ['gofood'],
    shopeefood: ['shopeefood'],
    'GO-SEND': ['shipper|instant|GO-SEND', 'shipper|same day|GO-SEND'],
    'Grab Express': ['shipper|instant|Grab Express', 'shipper|same day|Grab Express'],
    Paxel: ['shipper|express|Paxel'],
    JNE: ['shipper|regular|JNE', 'shipper|express|JNE', 'shipper|trucking|JNE'],
    Tiki: ['shipper|regular|Tiki', 'shipper|express|Tiki'],
    'J&T': ['shipper|regular|J&T'],
    Wahana: ['shipper|regular|Wahana'],
    SiCepat: ['shipper|regular|Sicepat', 'shipper|express|Sicepat', 'shipper|trucking|Sicepat'],
    Anteraja: ['shipper|regular|Anteraja', 'shipper|express|Anteraja'],
    'Ninja Xpress': ['shipper|regular|Ninja Xpress'],
    'Lion Parcel': ['shipper|regular|Lion Parcel', 'shipper|express|Lion Parcel'],
    'Indah Cargo': ['shipper|trucking|Indah Cargo'],
    RPX: ['shipper|trucking|RPX'],
    Borzo: ['shipper|instant|Borzo'],
    Lalamove: ['shipper|instant|Lalamove']
};

export const ORDER_HISTORY_SHIPPING_TYPE_IMAGE_URL_MAP = {
    'general.OpShippingSelfShipping': '/assets/images/shipping/KurirToko.svg',
    GoFood: '/assets/images/shipping/GoFood.svg',
    GrabFood: '/assets/images/shipping/GrabFood.svg',
    ShopeeFood: '/assets/images/shipping/ShopeeFood.svg',
    'GO-SEND': '/assets/images/shipping/GoSend.svg',
    'Grab Express': '/assets/images/shipping/GrabExpress.svg',
    Paxel: '/assets/images/shipping/Paxel.svg',
    JNE: '/assets/images/shipping/JNE.svg',
    Tiki: '/assets/images/shipping/Tiki.svg',
    'J&T': '/assets/images/shipping/JNT.svg',
    Wahana: '/assets/images/shipping/Wahana.svg',
    SiCepat: '/assets/images/shipping/SiCepat.svg',
    Anteraja: '/assets/images/shipping/Anteraja.svg',
    'Ninja Xpress': '/assets/images/shipping/NinjaXpress.svg',
    'Lion Parcel': '/assets/images/shipping/LionParcel.svg',
    'Indah Cargo': '/assets/images/shipping/IndahCargo.svg',
    RPX: '/assets/images/shipping/RPX.svg',
    Borzo: '/assets/images/shipping/Borzo.svg',
    Lalamove: '/assets/images/shipping/Lalamove.svg'
};

export const ORDER_HISTORY_PAYMENT_TYPE_IMAGE_URL_MAP = {
    cash: '/assets/images/payment/Cash.svg',
    'manual-edc': '/assets/images/payment/EDC.svg',
    BCA: '/assets/images/payment/BCA.svg',
    BNI: '/assets/images/payment/BNI.svg',
    BRI: '/assets/images/payment/BRI.svg',
    BSS: '/assets/images/payment/BSS.svg',
    gofood: '/assets/images/payment/GoFood.svg',
    grabfood: '/assets/images/payment/GrabFood.svg',
    shopeefood: '/assets/images/payment/ShopeeFood.svg',
    MANDIRI: '/assets/images/payment/Mandiri.svg',
    PERMATA: '/assets/images/payment/Permata.svg',
    QRIS: '/assets/images/payment/QRIS.svg',
    DANA: '/assets/images/payment/DANA.svg',
    LINKAJA: '/assets/images/payment/Linkaja.svg',
    OVO: '/assets/images/payment/OVO.svg',
    SHOPEEPAY: '/assets/images/payment/ShopeePay.svg',
    custom: '/assets/images/payment/Custom.svg',
    CREDIT_CARD: '/assets/images/payment/CreditCard.svg',
    others: '/assets/images/payment/Others.svg',
    split_payment: '/assets/images/payment/SplitPay.svg'
};

// Vouchers
export const VOUCHERS_STATUS_TEXT = {
    active: 'vouchers.OpStatusActive',
    upcoming: 'vouchers.OpStatusUpcoming',
    finished: 'vouchers.OpStatusFinished'
};

export const VOUCHERS_STATUS_BG_COLOR = {
    active: '#E9F6FF',
    upcoming: '#E6F7ED',
    finished: '#FCEDEA'
};

export const VOUCHERS_STATUS_TEXT_COLOR = {
    active: '#1EA5FC',
    upcoming: '#00AB4F',
    finished: '#DE4E29'
};

export const VOUCHERS_SHIPPING_METHOD = [
    { value: 'dine_in', label: 'general.OpShippingDineIn', type: ['order'] },
    { value: 'pickup', label: 'general.OpShippingPickup', type: ['order'] },
    { value: 'self_shipping', label: 'general.OpShippingSelfShipping', type: ['order', 'shipping'] },
    { value: 'shipper', label: 'general.OpShippingLogistic', type: ['order', 'shipping'] }
];

export const VOUCHERS_PAYMENT_METHOD = [
    { value: 'xendit', label: 'vouchers.OpPaymentXendit', type: ['order', 'shipping'] },
    { value: 'cash', label: 'general.OpPaymentCash', type: ['order'] },
    { value: 'manual-edc', label: 'general.OpPaymentManualEdc', type: ['order'] }
];

// Languages
export const LANGUAGE_FLAGS = {
    ID: '/assets/images/flags/id.svg',
    EN: '/assets/images/flags/en.svg'
};

export const LANGUAGE_OPTIONS = [
    { label: 'general.OpBahasa', value: 'ID' },
    { label: 'general.OpEnglish', value: 'EN' }
];

// Attendance Schedule
export const ATTENDANCE_SCHEDULE_REPEAT_MAP = {
    DAILY: 'DAILY',
    WORK_DAY: 'WORK_DAY',
    WEEKEND: 'WEEKEND',
    CUSTOM: 'CUSTOM',
    NONE: 'NONE'
};

export const ATTENDANCE_SCHEDULE_REPEAT_OPTIONS = [
    { label: 'attendance_schedule.OpEveryDay', value: ATTENDANCE_SCHEDULE_REPEAT_MAP.DAILY },
    { label: 'attendance_schedule.OpEveryWorkDay', value: ATTENDANCE_SCHEDULE_REPEAT_MAP.WORK_DAY },
    { label: 'attendance_schedule.OpEveryWeekend', value: ATTENDANCE_SCHEDULE_REPEAT_MAP.WEEKEND },
    { label: 'attendance_schedule.OpCustomDay', value: ATTENDANCE_SCHEDULE_REPEAT_MAP.CUSTOM },
    { label: 'attendance_schedule.OpNoRepeat', value: ATTENDANCE_SCHEDULE_REPEAT_MAP.NONE }
];

export const ATTENDANCE_SCHEDULE_STAFF_ROLE_MAP = {
    owner: 'attendance_schedule.OpEmployeeOwner',
    manager: 'attendance_schedule.OpEmployeeManager',
    admin: 'attendance_schedule.OpEmployeeAdmin',
    absent_only: 'attendance_schedule.OpEmployeeAbsentOnly'
};

export const ATTENDANCE_SCHEDULE_STAFF_ROLE_OPTIONS = [
    { label: 'attendance_schedule.OpEmployeeAllPosition', value: 'all' },
    { label: 'attendance_schedule.OpEmployeeManager', value: 'manager' },
    { label: 'attendance_schedule.OpEmployeeAdmin', value: 'admin' },
    { label: 'attendance_schedule.OpEmployeeAbsentOnly', value: 'absent_only' }
];
