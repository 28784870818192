// assets
import { ReadOutlined, ShoppingCartOutlined } from '@ant-design/icons';

import CommonMenuIcon from 'components/commons/MenuIcon';
import SupplierIcon from 'assets/images/icons/menuSVG/supplier.svg';
import InventoryCategoryIcon from 'assets/images/icons/menuSVG/inventoryCategory.svg';
import RecipeIcon from 'assets/images/icons/menuSVG/recipe.svg';
import IngredientIcon from 'assets/images/icons/menuSVG/ingredient.svg';
import InventoryFlowIcon from 'assets/images/icons/menuSVG/inventory-flow.svg';

// icons
const icons = {
    ReadOutlined,
    ShoppingCartOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const getInventoryMenu = ({ $t }) => ({
    id: 'group-inventory',
    title: $t('sidebar.OpMenuGroupInventory'),
    type: 'group',
    children: [
        {
            id: 'ingredients',
            title: $t('ingredients.OpTitle'),
            type: 'item',
            url: '/ingredients',
            icon: CommonMenuIcon(IngredientIcon),
            breadcrumbs: false
        },
        {
            id: 'inventory-flow',
            title: $t('inventory_flow.OpTitle'),
            type: 'item',
            url: '/inventory-flow',
            icon: CommonMenuIcon(InventoryFlowIcon),
            breadcrumbs: false
        },
        {
            id: 'supplier',
            title: $t('suppliers.OpTitle'),
            type: 'item',
            url: '/suppliers',
            icon: CommonMenuIcon(SupplierIcon),
            breadcrumbs: false
        },
        {
            id: 'categories',
            title: $t('inventory_category.OpTitle'),
            type: 'item',
            url: '/categories-inventory',
            icon: CommonMenuIcon(InventoryCategoryIcon),
            breadcrumbs: false
        },
        {
            id: 'recipes',
            title: $t('recipes.OpTitle'),
            type: 'item',
            url: '/recipes',
            icon: CommonMenuIcon(RecipeIcon),
            breadcrumbs: false
        }
    ]
});

export default getInventoryMenu;
