// assets
import CommonMenuIcon from 'components/commons/MenuIcon';

import TableAreaIcon from 'assets/images/icons/menuSVG/table-area.svg';
import TableManagementIcon from 'assets/images/icons/menuSVG/table-management.svg';

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const getTableManagementMenu = ({ $t }) => {
    return {
        id: 'table-management-menu',
        title: $t('sidebar.OpMenuGroupTableManagement'),
        type: 'group',
        children: [
            {
                id: 'table-area',
                title: $t('area.OpTitle'),
                type: 'item',
                url: '/table-management/area',
                icon: CommonMenuIcon(TableAreaIcon),
                breadcrumbs: false
            },
            {
                id: 'table-management',
                title: $t('table.OpTitle'),
                type: 'item',
                url: '/table-management/table',
                icon: CommonMenuIcon(TableManagementIcon),
                breadcrumbs: false
            }
        ]
    };
};

export default getTableManagementMenu;
